import React, {useState}  from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import ThankyouSection from '../components/ThankyouSection'
import {Helmet} from 'react-helmet'

const ThankYou = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Helmet>
                <title>iVip | Thank You</title>
                <meta name="description" content="EXCLUSIVE INSURANCE PACKAGE" />
            </Helmet>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={ toggle }/>
            <ThankyouSection />
            <Footer />
        </>
    )
}

export default ThankYou
