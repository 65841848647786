import React, {useState, useEffect} from 'react'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, LogoWrap, Img } from './NavbarElements'
import myLogo from '../../images/ivip.png'
import {  FaBars } from 'react-icons/fa'
import {animateScroll as scroll} from 'react-scroll'
import i18n from '../../i18n'
import { useTranslation } from "react-i18next";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Form from '../Form';
import styled from 'styled-components';

const Heading = styled.h1`
  text-align: center;
  padding:10px 10px;
`

const Subtitle = styled.p`
  text-align: center;
  padding: 5px 10px;
  margin-bottom: 10px;
`

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)
    const [locale, setlocale] = useState("en")
    

    const [open, setOpen] = useState(false);
 
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
  
     
    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        }
        else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const { t } = useTranslation();

    

    const changeLanguange = () => {
        return () => {
            if (locale === "en") {
                i18n.changeLanguage("bm");
                setlocale("bm")
            } else {
                i18n.changeLanguage("en");
                setlocale("en")
            }
            
        }
    }



    
        
    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        <LogoWrap>
                            <Img src={myLogo} alt='logo' />
                        </LogoWrap>
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="about">
                               {t("Itemnav1")}
                            </NavLinks>
                        </NavItem>
                        
                        <NavItem>
                            <NavLinks to="#" onClick={changeLanguange(locale)}>
                                {locale === 'en' ? 'Bahasa Melayu' : 'English'}
                            </NavLinks >
                        </NavItem>
                        <NavBtn>
                            <NavBtnLink to="#" onClick={onOpenModal}>
                            { t("BtnLabel") }
                            </NavBtnLink>
                        </NavBtn>
                    </NavMenu>
                </NavbarContainer>
            </Nav>

        <Modal open={open} onClose={onCloseModal} focusTrapped={false} blockScroll={false} center>
          <Heading>{t("ModalHeader")}</Heading>
          <Subtitle>{t("ModalDesc")}</Subtitle>
                <Form />
        </Modal>
        </>
    )
}

export default Navbar;
