import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Form from '../Form';
import { Button1 } from '../HeroSection/HeroElement';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import './custom.css'


const Heading = styled.h1`
  text-align: center;
  padding-top:3rem;
`

const Subtitle = styled.p`
  text-align: center;
  padding: 5px 10px;
  margin-bottom: 10px;
`



export const ModalQuote = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
  const [open, setOpen] = useState(false);
 
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const { t } = useTranslation();


 
  return (
    <> 
      <Button1 to="#" onClick={onOpenModal} onMouseEnter={onHover} onMouseLeave={onHover}
        primary="true"
        dark="true">{ t("BtnLabel") }</Button1>
        <Modal open={open} onClose={onCloseModal} focusTrapped={false} blockScroll={false} center>
          <Heading>{t("ModalHeader")}</Heading>
        <Subtitle>{t("ModalDesc")}</Subtitle> 
        <Form />
        </Modal>
    </>
  );
};


