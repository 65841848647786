import React, {useState}  from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import ThankyouSubSection from '../components/ThankyouSubSection'
import {Helmet} from 'react-helmet'

const Subscribe = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Helmet>
                <title>iVip | Thank You</title>
                <meta name="description" content="EXCLUSIVE INSURANCE PACKAGE" />
            </Helmet>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={ toggle }/>
            <ThankyouSubSection />
            <Footer />
        </>
    )
}

export default Subscribe
