import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './locales/en/translationen.json'
import translationBM from './locales/bm/translationbm.json'


//The translation 
const resources = {
    en: {
        translation: translationEN
    },
    bm: {
        translation: translationBM
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;