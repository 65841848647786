import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import { Button2 } from '../HeroSection/HeroElement';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

const Heading = styled.h1`
  text-align: center;
  padding-top:3rem;
`

const Subtitle = styled.p`
  text-align: center;
  padding: 5px 10px;
  margin-bottom: 10px;
`

const ButtonCancel = styled.button`
  width: 340px;
  height: 56px;
  background: #E5E5E5;
  color: #000;
  outline:none;
  font-weight: bolder;
  border:none;
  cursor:pointer;
  white-space:nowrap;
  border-radius:5px;
  display:flex;
  justify-content:center;
  align-items:center;
  padding: ${({ big }) => (big ? '35px 100px' : '20px 50px')};

  @media only screen and (max-width: 375px) {
    width: 290px;
  }
`



const Row = styled.div`

:after{
content: "";
display: table;
clear: both;
}
`

const Column = styled.div`
float: left;
width: 50%;
padding: 10px;

@media screen and (max-width: 800px){
  width: 100%; 
  float:inherit;
}
`

const FormLabel = styled.label`
  display:flex;
  padding: 5px;
`

const FormInput = styled.input`
  width: 340px;
  height: 48px;
  background: #fff;
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 1rem 0.5rem;
  font-size: 16px;

  ::placeholder{
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (max-width: 800px){
    width: 100%;  
  }
`

const ButtonSubmit = styled.button`
  width: 340px;
  height: 56px;
  background: #FFB61C;
  color: #000;
  outline:none;
  border:none;
  cursor:pointer;
  font-weight: bolder;
  white-space:nowrap;
  border-radius:5px;
  display:flex;
  justify-content:center;
  align-items:center;
  padding: ${({ big }) => (big ? '35px 100px' : '20px 50px')};

  @media only screen and (max-width: 375px) {
    width: 290px;
  }
`


const LeadSource = styled.div`
display:none;
`
 
export const ModalSubs = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
  const [open, setOpen] = useState(false);
 
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const { t } = useTranslation();
 
  return (
    <div> 
      <Button2 to="#"  onClick={onOpenModal} onMouseEnter={onHover} onMouseLeave={onHover}
        primary="true"
        dark="true">{ t("BtnLabel1") }</Button2>
      <Modal open={open} onClose={onCloseModal} focusTrapped={false} blockScroll={false} center>
         <Heading>{t("ModalHeaderSubs")}</Heading>
          <Subtitle>{t("ModalDescSubs")}</Subtitle>

        <form acceptCharset='UTF-8' method='POST' name='WebForm4365773000015141212' action='https://crm.zoho.com/crm/WebForm' >

            <FormInput type="text" name="xnQsjsdp" style={{
                display: 'none'
              }} value='f714538a1c1aa9fecc3d4ecec2532f342258db00c1e63ab3b526c63bb25a055b' readOnly={true} />

              <FormInput type="hidden" name="zc_gad" id='zc_gad' style={{
                display: 'none'
              }} value='' />

              <FormInput type="text" name="xmIwtLD" style={{
                display: 'none'
              }} value='215620394e7e36c2654658d284bc6f3f50b7b1ce52c2b7912933e6ff9e3cf622' />

              <FormInput type="text" name="actionType" style={{
                display: 'none'
              }} value='Q3VzdG9tTW9kdWxlMzQ=' />

              <FormInput type="text" name="returnURL" style={{
                display: 'none'
              }} value='https://ivip.com.my/subscribe' />

                  <Row>
                    <Column>
                      <FormLabel htmlFor="NAME">
                      {t("Fullname")}
                      </FormLabel>
                  <FormInput type="text" id="NAME" name="NAME" placeholder="Ahmad Syafiq" maxLength="120" />
                    </Column>
                    <Column>
                      <FormLabel htmlFor="Email">
                      {t("Email")}
                      </FormLabel>
                      <FormInput type="email" formEncType='email' id='Email' name="Email" maxLength='100' placeholder="e.g name@email.com" />
                    </Column>
              </Row>

              <LeadSource>
              <FormLabel htmlFor="Lead_Source" >
                  lead source
                </FormLabel>
                <select id="COBJ34CF1" name="COBJ34CF1" >
                  <option value="-None-">-None-</option>
                  <option selected={true} value="IVIP&#x20;WEBPAGE">IVIP WEBPAGE</option>
                  <option value="Others">Others</option>
                </select>
              </LeadSource>
              
                  <Row>
                    <Column>
                      <ButtonCancel type="button" onClick={onCloseModal}>{t("BtnLabel2")}</ButtonCancel>
                    </Column>

                    <Column>
                      <ButtonSubmit type="submit">{t("BtnLabel1")}</ButtonSubmit>
                    </Column>
              </Row>
                </form>
      </Modal>
    </div>
  );
};


