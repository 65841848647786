import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const FooterContainer = styled.footer`
    background: linear-gradient(180deg, #FF9900 0%, rgba(255, 255, 255, 0) 100%), #FFB61C;
`

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px){
        padding: 0;
    }
`

export const FooterTextWrapper = styled.div`
    display: inline-block;
    text-align: center;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #000;

    @media screen and (max-width: 420px){
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.h1`
    font-size: 50px;
    margin-bottom: 16px;

    @media screen and (max-width: 420px){
        font-size: 35px;
    }
`

export const FooterLinkDescription = styled.h3`
    font-size: 18px;
    margin-bottom: 10px;

    @media screen and (max-width: 420px){
        font-size: 15px;
    }
`

export const FooterLinkDescription1 = styled.h4`
    font-size: 16px;
    margin-bottom: 16px;

    @media screen and (max-width: 420px){
        font-size: 13px;
    }
`

export const FooterLink = styled(Link)`
    color: #000;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover{
        color: #fff;
        transition: 0.3s ease-out;
    }
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin:40px auto 0 auto;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`

export const SocialLogo = styled(Link)`
    color: #000;
    justify-self: start;
    cursor: pointer;
    text-decoration:none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
`

export const WebsiteRights = styled.p`
    color: #000;
    font-size: 16px;
    margin-bottom: 16px;
    text-align: center;
`

export const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    margin-bottom: 15px;
`

export const SocialIconLink = styled.a`
color: #000;
font-size: 20px;
margin: 0px 10px;
`

export const Label = styled.p`
    color: #000;
    font-size: 16px;
    vertical-align: text-top;
    display: inline-block;
    margin: 0px 20px;
`

export const Mailto = styled.a`
    color: #000;
    text-decoration: none;
    cursor:pointer;
`