import React , {useState} from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute, } from './SidebarElement'
import i18n from '../../i18n'
import { useTranslation } from "react-i18next";
import { Modal } from 'react-responsive-modal';
import Form from '../Form';
import styled from 'styled-components';

const Heading = styled.h1`
  text-align: center;
  padding:10px 10px;
`

const Subtitle = styled.p`
  text-align: center;
  padding: 5px 10px;
  margin-bottom: 10px;
`


const Sidebar = ({ isOpen, toggle }) => {
    const { t } = useTranslation();
    const [locale, setlocale] = useState("en")

    const [open, setOpen] = useState(false);
 
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const changeLanguange = () => {
        return () => {
            if (locale === "en") {
                i18n.changeLanguage("bm");
                setlocale("bm")
            } else {
                i18n.changeLanguage("en");
                setlocale("en")
            }
            
        }
    }
      
    return (
        <>
            <SidebarContainer isOpen={isOpen} onClick={toggle}>
                <Icon onClick={toggle}>
                    <CloseIcon/>
                </Icon>
                <SidebarWrapper>
                    <SidebarMenu>
                        <SidebarLink to='about' onClick={toggle}>
                            {t("Itemnav1")}
                        </SidebarLink>
                        <SidebarLink to='#' onClick={changeLanguange(locale)}>
                        {locale === 'en' ? 'Bahasa Melayu' : 'English'}
                        </SidebarLink>
                        <SideBtnWrap>
                            <SidebarRoute to='#' onClick={onOpenModal}>
                            { t("BtnLabel") }
                            </SidebarRoute>
                        </SideBtnWrap>
                    </SidebarMenu>
                </SidebarWrapper>
            </SidebarContainer>   

            <Modal open={open} onClose={onCloseModal} focusTrapped={false} blockScroll={false} center>
          <Heading>{t("ModalHeader")}</Heading>
          <Subtitle>{t("ModalDesc")}</Subtitle>
                <Form />
        </Modal>
        </>
    )
}

export default Sidebar
