import React , {useEffect,useState} from 'react'
import {InfoContainer, InfoWrapper, InfoRow, Column1, Column2, Column3, TextWrapper,Heading,Subtitle,BtnWrap} from '../InfoElement'
import { Parallax } from 'react-parallax'
import { useSpring, animated } from 'react-spring'
import ImageDesk from '../../images/Section-8.jpg'
import ImageMobile from '../../images/Mobile/Section-8.png'
import { useTranslation } from "react-i18next";
import { ModalQuote } from '../ModalQuote'
import Aos from 'aos';
import 'aos/dist/aos.css';


const EightSection = ({
      imgStart, lightText,darkText,alt,primary,dark,dark2
      
}) => {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    })
    const props = useSpring({ opacity: 1, from: { opacity: 0 } });
    const { t } = useTranslation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageUrl = windowWidth >= 650 ? ImageDesk : ImageMobile;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);
    return (
        <>
            <Parallax 
            bgImage={imageUrl}
                bgImageAlt={alt}
                strength={300}>
                <animated.div style={props}>
                    <InfoContainer>
                        <InfoWrapper>
                            <InfoRow imgStart={imgStart}>
                                <Column1>
                                    <TextWrapper data-aos="fade-down">
                                        <Heading lightText={lightText}>{ t("EightSecHeadline") }</Heading>
                                    </TextWrapper>

                                </Column1>
                                <Column2>
                                    <TextWrapper data-aos="fade-left">
                                        <Subtitle darkText={darkText}>{t("EightSecDesc")}</Subtitle>
                                        <Subtitle darkText={darkText}>{ t("EightSecBtmDesc") }</Subtitle>
                                    </TextWrapper>
                                </Column2>
                                <Column3>
                                    <BtnWrap data-aos="fade-up">
                                         <ModalQuote />
                                    </BtnWrap>
                                </Column3>
                            </InfoRow>
                        </InfoWrapper>
                    </InfoContainer>
                </animated.div>
            </Parallax>
        </>
    )
}

export default EightSection
