import React, { useState,useEffect}from 'react'
import { AboutContainer, AboutContent, AboutH1, AboutP3, ApegIcon } from './ThankyouElement'
import { Parallax } from 'react-parallax'
import ImageDesk from '../../images/About-us.jpg'
import ImageMobile from '../../images/Mobile/About-us.png'
import Icon from '../../images/APEG-SYSTEM.png'
import { useTranslation } from "react-i18next";

import {useSpring, animated} from 'react-spring'

const ThankyouSubSection = () => {

    const props = useSpring({ opacity: 1, from: { opacity: 0 } });
    const { t } = useTranslation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageUrl = windowWidth >= 650 ? ImageDesk : ImageMobile;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);
    return (
        <>
        <Parallax bgImage={imageUrl}
                bgImageAlt="the cat"
    bgImageSizes= "1440px"
    strength={300}><animated.div style={props}>
                <AboutContainer>
                    
                <AboutContent>
                            <AboutH1>{t("ThankyouSubs")}</AboutH1>
                    
                    <ApegIcon src={Icon} alt="Apeg System" />
                    <AboutP3>{t("AboutP3")}</AboutP3>

                        </AboutContent>
                   
                    </AboutContainer>
                </animated.div>
        </Parallax>
        </>
    )
}

export default ThankyouSubSection
