

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Bank',
    headline: 'UNLIMITED TOWING ASSISTANCE',
    description: 'Unlimited 24-hour towing assistance nationwide within Malaysia (excluding islands except Penang, Langkawi and Labuan) towing assistance with no distance or charge limits (exluding toll charges). Most basic policies usually provide Free Towing for up to a preset walue or for a limited mileage only.',
    buttonLabel: 'GET FREE QUOTATION',
    imgStart: false,
    img: require('../../images/Section-2.jpg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};