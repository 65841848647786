import React, {useState , useEffect} from 'react'
import ImageDesk from '../../images/Section-1.jpg'
import ImageMobile from '../../images/Mobile/Section-1.png'
import { HeroContainer, HeroContent, HeroP,HeroP2, HeroBtnWrapper, BottomLogo} from './HeroElement'
import { Parallax } from 'react-parallax'
import Icon from '../../images/zurichtakaful.png'
import { useSpring, animated } from 'react-spring'
import { Modal } from '../Modal'
import { useTranslation } from "react-i18next";
import { ModalQuote } from '../ModalQuote';
import { ModalSubs } from '../ModalSubs'
import './style.css';



function HeroSection() {

   

    const props = useSpring({ opacity: 1 , marginTop: -150 , from: { opacity: 0 , marginTop: 0} });
    
    const [showModal, setShowModal] = useState(false);

    const { t } = useTranslation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageUrl = windowWidth >= 650 ? ImageDesk : ImageMobile;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);
    return (
        <Parallax bgImage={imageUrl}
            bgImageAlt="the cat"
            strength={300}>
            <Modal showModal={showModal} setShowModal={setShowModal} />
            
            <HeroContainer id="home">
                <animated.div style={props}>      
            <HeroContent>
                <h1 className="styleheading">{t("Maintitle")}</h1>    
                <HeroP>{t("Maindesc")}.</HeroP>
                    <HeroBtnWrapper>
                            <ModalQuote />
                            <ModalSubs  />        
                    </HeroBtnWrapper>
                        <HeroP2>{t("Btmline")}</HeroP2>
                        <BottomLogo src={Icon} alt="Zurich " />
                    </HeroContent>
                </animated.div>
                </HeroContainer>
         
</Parallax>
    )
}

export default HeroSection
