import React, { useEffect }from 'react';
import './App.css';
import 'animate.css';
import { BrowserRouter as Router, Switch,Route } from 'react-router-dom'
import Home from './pages/index';
import AboutUs from './pages/about';
import ThankYou from './pages/thankyou';
import Subscribe from './pages/subscribe';
import ReactGA from 'react-ga';

function App() {

  useEffect(() => {
    ReactGA.initialize('UA-180946310-2');
    // To Report Page View 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={AboutUs} exact />
        <Route path="/thankyou" component={ThankYou} exact />
        <Route path="/subscribe" component={Subscribe} exact />
      </Switch>
    </Router>
  );
}

export default App;
