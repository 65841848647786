import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'


export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent')};
    height: 120px;
    margin-top: -140px;
    display:flex;
    justify-content: center;
    align-items:center;
    font-size:1rem;
    position:sticky;
    top:0;
    z-index:10;
    
    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`


export const NavbarContainer = styled.div`
  display: flex;
  justify-content:flex-start;
  height:70px;
  z-index:1;
  width:93%;
  padding: 0 25px;
`

export const NavLogo = styled(LinkR)`
    color:#fff;
    justify-self:flex-start;
    cursor:pointer;
    font-size:1.5rem;
    display:flex;

`
export const LogoWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    margin: 0 0 10px -60px; 
    padding-right: 0;
    
    @media screen and (max-width: 768px){
        width: 190px;
        margin-top: 0.5rem;
    }
`

export const MobileIcon = styled.div`
display: none;

@media screen and (max-width: 768px){
    display: block;
    position: absolute;
    right:0;
    transform:translate(-100%, 60%);
    font-size:1.8rem;
    cursor:pointer;
    color:#FFB61C;
}
`

export const NavMenu = styled.ul`
display:flex;
align-items:center;
list-style:none;
text-align:center;
margin-right:-22px;
width: 100vw;
white-space: nowrap;


@media screen and (max-width:768px){
    display:none;
}
`

export const NavItem = styled.li`
    height:80px;
`

export const NavLinks = styled(LinkR)`
    color:#fff;
    display:flex;
    align-text:center;
    text-decoration:none;
    padding:2rem;
    height:100%;
    cursor:pointer;

    &.active{
        border-bottom:3px solid #01bf71;
    }

    `


export const NavBtn = styled.nav`
 display:flex;
 align-items: center;
 padding:10px;
 justify-content: flex-end;
 width: 100%;
 
 @media screen and (max-width: 768px) {
     display:none;
 }

`

export const NavBtnLink = styled(LinkR)`
border-radius:5px;
background:#FFB61C;
white-space:nowrap;
padding:10px 22px;
font-family: roboto-medium;
font-weight: 700;
text-transform: uppercase;
color:#000;
font-size:14px;
line-height: 16.41px;
outline:none;
border:none;
cursor:pointer;
transition: all 0.2s ease-in-out;
text-decoration:none;

&:hover {
    transition: all 0.2 ease-in-out;
    color:#FFB61C;
    background:transparent;
    border: 1px solid #FFB61C; 
}

`