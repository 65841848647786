import styled from 'styled-components'
import { Link } from 'react-scroll'

export const HeroContainer = styled.div`
display:grid;
justify-content:center;
align-items:center;
padding:0 30px;
height:100vh;
position:relative;
z-index:1;

:before {
    content: '';
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0 ,0, 0, 0.3) 80%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index:2;
}

@media screen and (max-width: 600px){
    padding:0;
}


`


export const HeroBg = styled.div`
 position:absolute;
 top:0;
 right:0;
 bottom:0;
 left:0;
 width:100%;
 height:100%;
 overflow:hidden;
`

export const VideoBg = styled.img`
    width:100%;
    height:100%;
    -o-object-fit:cover;
    object-fit:cover;
    background:#232a34;
`

export const HeroContent = styled.div`
z-index:3;
max-width:1200px;
margin-top: 19rem;
position:relative;
padding:8px 24px;
display:flex;
flex-direction:column;
align-items:center;


@media screen and (max-width: 375px){
    margin-top: 250px;
    padding:0;
}
`


export const HeroH1 = styled.h1`
color: #fff;
font-size:54px;
font-family: gilroy;
text-transform: uppercase;
line-height: 63.28px;
text-align:center;

@media screen and (max-width:768px){
    font-size:32px;
    line-height: 1.5;
}

`


export const HeroP = styled.p`
 margin-top:24px;
 margin-bottom: 40px;
 color:#fff;
 font-size: 18px;
 line-height: 21.09px;
 text-align:center;
 max-width:600px;

 @media screen and (max-width:768px){
    margin-bottom: 20px;
    font-size:16px;
}
`

export const HeroP2 = styled.p`
padding-top: 50px;
padding-bottom: 15px;
font-weight: 400;
font-family: roboto-regular;
line-height: 16.41px;
color:#fff;
font-size: 14px;
text-align:center;
z-index: 2;

 @media screen and (max-width:768px){
    padding-top: 30px;
    font-size:16px;
}


`

export const HeroBtnWrapper = styled.div`

    align-items:center;
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap:16px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 375px){
        grid-template-columns: auto ;
        padding: 0 10px;
    }

`
export const Button1 = styled(Link)`
    border-radius:5px;
    background: #FFB61C;
    font-weight: 700;
    text-transform: uppercase;
    width: 340px;
    height: 56px;
    white-space:nowrap;
    padding: ${({ big }) => (big ? '35px 100px' : '20px 50px')};
    color: ${({ dark }) => (dark ? '#010606' : '#fff')};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline:none;
    border:none;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    transition:all 0.2s ease-in-out;



    @media screen and (max-width: 480px){
        width: 170px;
        font-size: 12px;
    }

    @media screen and (max-width: 375px){
        width: 350px;
        font-size: 16px;
    }

`

export const Button2 = styled(Link)`
    border-radius:5px;
    background: transparent;
    border: 1px solid #FFB61C; 
    text-transform: uppercase;
    font-weight: 700;
    width: 340px;
    height: 56px;
    white-space:nowrap;
    padding: ${({ big }) => (big ? '35px 100px' : '20px 50px')};
    color: ${({ dark }) => (dark ? '#FFB61C' : '#fff')};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    transition:all 0.2s ease-in-out;


    @media screen and (max-width: 680px){
        width: 220px;
    }

    @media screen and (max-width: 480px){
        width: 170px;
        font-size: 12px;
    }

    @media screen and (max-width: 375px){
        width: 350px;
        font-size: 16px;
    }

`

export const CardWrapper = styled.div`
z-index:3;
max-width:1200px;
margin-top: 150px;
position:relative;
padding:8px 24px;
display:flex;
flex-direction:column;
align-items:center;
`

export const BottomLogo = styled.img`
    width: 84px;
    height: 70px;
    position:relative;

    @media screen and (max-width:768px){
        width: 50px;
    height: auto;
    }
`