import React, {useState} from 'react'
import Footer from '../components/Footer'
import HeroSection from '../components/HeroSection'
import SecondSection from '../components/SecondSection'
import { homeObjOne } from '../components/SecondSection/Data'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import ThirdSection from '../components/ThirdSection'
import ForthSection from '../components/ForthSection'
import FifthSection from '../components/FifthSection'
import SixthSection from '../components/SixthSection'
import SeventhSection from '../components/SeventhSection'
import EightSection from '../components/EightSection'
import NinethSection from '../components/NinethSection'
import Card from '../components/Card'
import {Helmet} from 'react-helmet'

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Helmet>
                <title>iVip | Home</title>
                <meta name="description" content="EXCLUSIVE INSURANCE PACKAGE" />
            </Helmet>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection />
            <Card />
            <SecondSection {...homeObjOne} />
            <ThirdSection {...homeObjOne} />
            <ForthSection {...homeObjOne} />
            <FifthSection {...homeObjOne} />
            <SixthSection {...homeObjOne} />
            <SeventhSection {...homeObjOne} />
            <EightSection {...homeObjOne} />
            <NinethSection {...homeObjOne} />
            <Footer />
        </>
    )
}

export default Home
