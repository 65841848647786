import styled from 'styled-components'
import { Link } from 'react-scroll'


export const InfoContainer = styled.div`
    color:#fff;
    background-size: cover;
    background-position: center bottom;
    position:relative;
    z-index:1;

    :before {
        content: '';
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0 ,0, 0, 0.6) 100%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
        z-index:-1;
    }


    @media screen and (max-width: 768px) {
        padding: 154px 0;
    }
`


export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 100vh;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding:24px 24px;
    align-items: flex-end;
    justify-content: center;

`

export const InfoRow = styled.div`
    display:grid;
    grid-auto-rows: auto;
    align-items: baseline;
    grid-template-columns: 500px 500px;
    grid-template-areas: 'col1 col1' ' col3 col2';

    
    
    @media screen and (max-width: 768px){
        grid-template-columns: auto auto;
        grid-template-areas: 'col1 col1' 
                            'col2 col2'
                            'col3 col3';
    }

`

export const Column1 = styled.div`
    grid-area: col1;
`

export const Column2 = styled.div`
    grid-area: col2;
`
export const Column3 = styled.div`
    grid-area: col3;
`

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    margin-bottom: 2rem;

    
`

export const TopLine = styled.p`
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight:700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 50px;
    line-height: 1.1;
    font-family: gilroy;
    font-weight: bolder;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};
    
    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`

export const Subtitle = styled.p`
    max-width: 440px;
    font-size:18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
    
`

export const SubtitleBtm = styled.p`
    max-width: 440px;
    margin-bottom: 4.6rem;
    font-size:18px;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
    
    @media screen and (max-height: 812px){
        margin-bottom: 1.6rem;
    }
`

export const BtnWrap = styled.div`
    margin-bottom: 5rem;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 790px){
        margin-bottom: 1rem;
    }
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`

export const Button = styled(Link)`
    border-radius:5px;
    font-family: roboto;
    background: ${({ primary }) => (primary ? '#FFB61C' : '#fff')};
    white-space:nowrap;
    padding: ${({ big }) => (big ? '35px 100px' : '20px 50px')};
    color: ${({ dark }) => (dark ? '#010606' : '#fff')};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline:none;
    font-weight: bolder;
    border:none;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    transition:all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? '#fff' : '#FFB61C')};
    }

    @media screen and (max-width: 680px){
        width: 220px
    }

    @media screen and (max-width: 480px){
        width: 170px;
        padding: 10px;
        font-size: 12px;
    }

`


