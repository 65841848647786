import React, {useEffect} from 'react'
import './style.css'
import Icon1 from '../../images/imgcard.jpg'
import { useTranslation } from "react-i18next";
import Aos from 'aos';
import 'aos/dist/aos.css';
import  styled  from 'styled-components';


const HeaderCard = styled.h1`
    padding-top: 3rem;

    @media screen and (max-width:414px){
        padding-top: 1rem;
        text-align: center;
    }
`

const SubtitleCard = styled.p`
    padding-top: 1rem;

    @media screen and (max-width:414px){
        padding-top: 1rem;
        text-align: center;
    }
`
function Card() {

    const { t } = useTranslation();

    useEffect(() => {
        Aos.init({ duration: 1000});
    })



    return (
        <>

            <div className="blog-card alt" data-aos="fade-up">
            <div className="meta">
                    <div className="photo" style={{backgroundImage: `url(${Icon1})`}}></div>
            </div>
            <div className="description">
                 <HeaderCard>{t("Cardtitle")}</HeaderCard>
                <SubtitleCard>{t("Carddesc")}</SubtitleCard>
            </div>
                </div>

        </>
    )
}

export default Card
