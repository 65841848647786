import React, {useState}  from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import AboutSection from '../components/AboutSection'
import {Helmet} from 'react-helmet'

const AboutUs = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Helmet>
                <title>iVip | About Us</title>
                <meta name="description" content="EXCLUSIVE INSURANCE PACKAGE" />
            </Helmet>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={ toggle }/>
            <AboutSection />
            <Footer />
        </>
    )
}

export default AboutUs
