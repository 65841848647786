import React from 'react'
import {FaFacebook} from 'react-icons/fa'
import {
    FooterContainer, FooterWrap, FooterLinksContainer, FooterTextWrapper,
    FooterLinkTitle, FooterLinkDescription,FooterLinkDescription1, SocialMedia, SocialMediaWrap, SocialIcons, SocialIconLink, WebsiteRights, Label,Mailto
} from './FooterElement'
import { useTranslation } from "react-i18next";

const Footer = () => {

    const { t } = useTranslation();

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterTextWrapper>
                            <FooterLinkTitle>{ t("FooterTitle") }</FooterLinkTitle>
                        <FooterLinkDescription to="#"><Mailto href="mailto:apeg@g-martgroup.com" >{t("FooterDescription")}</Mailto></FooterLinkDescription>
                        <FooterLinkDescription1 to="#">{t("FooterDescription1")}</FooterLinkDescription1>
                    </FooterTextWrapper>
                </FooterLinksContainer>

                <SocialMedia>
                    <SocialMediaWrap>
                    
                        <WebsiteRights>
                        { t("WebsiteRight") }
                        </WebsiteRights>
                        
                        <SocialIcons>
                            <SocialIconLink href='https://www.facebook.com/ivipmy' target='_blank' aria-label="Facebook">
                            <Label>{ t("LabelSocial") }</Label>
                            
                               <FaFacebook />
                            </SocialIconLink>
                            {/* <SocialIconLink href='https://www.facebook.com/ivipmy' target='_blank' aria-label="Facebook">
                                <FaInstagram />
                            </SocialIconLink> */}
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
