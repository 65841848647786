import styled from 'styled-components'


export const AboutContainer = styled.div`
display:grid;
justify-content:flex-start;
align-items:baseline;
padding:150px 30px;
height:100vh;
position:relative;
z-index:1;

:before {
    content: '';
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0 ,0, 0, 0.3) 80%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index:2;
}
`

export const AboutContent = styled.div`
z-index:3;
max-width:1200px;
margin: 15rem 5rem;
position:absolute;
padding:10px 200px;
display:flex;
flex-direction:column;
align-items:center;
text-align:center;

@media screen and (max-width:960px){
    padding:0 30px;
}

@media screen and (max-width:667px){
    margin: 15rem 1rem;
}

`

export const AboutH1 = styled.h1`
color: #fff;
font-size:44px;
font-family: gilroy;
font-weight:bold;
text-transform: uppercase;
line-height: 51.56px;
text-align:center;

@media screen and (max-width:768px){
    font-size:22px;
}

@media screen and (max-width:667px){
    font-size:18px;
    line-height: 1;
}

@media screen and (max-width: 480px){
    font-size:16px;
    line-height: 1;
}
`



export const AboutP = styled.p`
 margin-top:2rem;
 margin-bottom: 5px;
 text-align:justify;
 color:#fff;
 font-family: roboto-regular;
 font-size: 18px;
 line-height: 21.09px;

 @media screen and (max-width:768px){
    margin-bottom: 10px;
    font-size:12px;
    line-height: 1;
}

@media screen and (max-width: 480px){
    margin-bottom: 10px;
    font-size:12px;
    line-height: 1;
}
`

export const AboutP2 = styled.p`
margin-top:24px;
color:#fff;
font-family: roboto-regular;
font-size: 18px;
line-height: 21.09px;
text-align:justify;

 @media screen and (max-width:768px){
    font-size:12px;
    line-height: 1;
}

@media screen and (max-width: 480px){
    font-size:12px;
    line-height: 1;
}
`
export const ApegIcon = styled.img`
    width: 120px;
    height: auto;
    margin-top: 14rem;

    @media screen and (max-width:768px){
        width: 80px;
        margin-top: 16rem;
    }

    @media screen and (max-width:375px){
        width: 80px;
        margin-top: 17rem;
    }
    @media screen and (max-width:320px){
        width: 80px;
        margin-top: 8rem;
    }

    
`



export const AboutP3 = styled.p`
margin-bottom: 20px;
color:#fff;
font-family: roboto-regular;
font-size: 14px;
line-height: 16.41px;
text-align:justify;

 @media screen and (max-width:768px){
    margin-bottom: 100px;
    font-size:9px;
}

@media screen and (max-width: 480px){
    margin-bottom: 100px;
    font-size:12px;
}
`

