import React, { Component } from 'react'
import styled from 'styled-components';
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Row = styled.div`

:after{
content: "";
display: table;
clear: both;
}
`

const Column = styled.div`
float: left;
width: 50%;
padding: 10px;

@media screen and (max-width: 800px){
  width: 100%; 
  float:inherit;
}
`

const FormLabel = styled.label`
  display:flex;
  padding: 5px;
`


const FormInput = styled.input`
  width: 340px;
  height: 48px;
  background: #fff;
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 1rem 0.5rem;
  font-size: 15px;

  ::placeholder{
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (max-width: 800px){
    width: 100%;  
  }
`

const Checkbox = styled.input`
margin: 10px;
width: 20px;
height: 20px;
vertical-align: middle;
`

const ButtonSubmit = styled.button`
  width: 100%;
  height: 56px;
  background: rgb(255, 182, 28);
  color: #000;
  outline:none;
  font-weight:bolder;
  border-radius: 5px;
  justify-content:center;
  border:none;
  cursor:pointer;
  white-space:nowrap;
  display:flex;
  margin: 2rem auto;
  align-items:center;
  padding: ${({ big }) => (big ? '35px 100px' : '20px 50px')};

  @media only screen and (max-width: 375px) {
      width: 290px;
  }
`

const LeadSource = styled.div`
display:none;
`



class Form extends Component {
  

  render() {
    
      
    const { t } = this.props;

        return (
          <>
            <form action='https://crm.zoho.com/crm/WebToLeadForm' name='WebToLeads4365773000014713045' method='POST' accept-charset='UTF-8' onSubmit={this.submitForm}>
            
              
            <FormInput type='text' style={{
                display: 'none'
              }} name='xnQsjsdp' value='f714538a1c1aa9fecc3d4ecec2532f342258db00c1e63ab3b526c63bb25a055b'>
              </FormInput>
              
              <FormInput type='hidden' name='zc_gad' id='zc_gad' value=''></FormInput>
              
            <FormInput type='text' style={{
                display: 'none'
              }} name='xmIwtLD' value='215620394e7e36c2654658d284bc6f3fbe0ac3f8489a9c9971b05530925b0c93'>
              </FormInput>
              
            <FormInput type='text'  style={{
                display: 'none'
              }} name='actionType' value='TGVhZHM='>
            </FormInput>
              
            <FormInput type='text' style={{
                display: 'none'
              }} name='returnURL' value='https://ivip.com.my/thankyou' >
            </FormInput>

              
            <Row>
              <Column>
                <FormLabel htmlFor='Last_Name'>
                  {t('Owner')}
                </FormLabel>
                <FormInput type="text" id='Last_Name' name='Last Name' placeholder="Ahmad Shafiq" maxLength='80' />
              </Column>
          
              <Column>
                <FormLabel htmlFor='LEADCF1'>
                  {t("OwnerMykad")}
                </FormLabel>
                <FormInput  type="text" id='LEADCF1' name='LEADCF1' placeholder="e.g 9607185789" maxLength='255'/>
              </Column>
            </Row>

            <Row>
              <Column>
                <FormLabel htmlFor="Email">
                {t("Email")}
                </FormLabel>
                  <FormInput type="email" formEncType='email' id='Email' name='Email' maxLength='100' placeholder="e.g name@mail.com" required/>
              </Column>
              <Column>  
                <FormLabel htmlFor="Phone">
                  {t("ContactNo")}
                </FormLabel>
            
                <PhoneInput
                  containerStyle={{
                    height: '48px',
                  }}
                  inputStyle={{
                    height: '48px',
                    width: '100%',
                  }}
                  inputProps={{
                    name: "Phone",
                    id: "Phone"
                  }}
                  disableDropdown={true}
                  countryCodeEditable={false}
                  masks={{}}
                specialLabel={false}
                  maxLength="15" country="my" placeholder="e.g +60 12-3456-789" required />
              </Column>
            </Row>

            <Row>
              <Column>
                <FormLabel htmlFor="LEADCF12">
                {t("Make")}
                </FormLabel>
                <FormInput type="text" id='LEADCF12' name='LEADCF12' placeholder="e.g Perodua" maxLength="255" />
              </Column>
              <Column>
                <FormLabel htmlFor="LEADCF11">
                  {t("Model")}
                </FormLabel>
                  <FormInput type="text" id='LEADCF11' name='LEADCF11' placeholder="e.g Myvi 2019" maxLength="255" />
              </Column>
            </Row>

            <Row>
              <Column>
                <FormLabel htmlFor="LEADCF2">
                {t("RegNo")}
                </FormLabel>
                <FormInput type="text" id="LEADCF2" name="LEADCF2" placeholder="e.g WA 8073 K" maxLength="255" />
              </Column>
              <Column>
                <FormLabel htmlFor="LEADCF13">
                  {t("Postcode")}
                </FormLabel>
                <FormInput type="text" id="LEADCF13" name="LEADCF13" placeholder="e.g 40200" maxLength="255" />
              </Column>
            </Row>

            <Row>
              <Column>
                <FormLabel htmlFor="First_Name">
                {t("PromoCode")}
                </FormLabel>
                <FormInput type="text" id='First_Name' name='First Name' placeholder="e.g IVIP123" maxLength="255" />
              </Column>
            </Row>
            
            <Row>
                <Checkbox type="checkbox" required={true} />
                <label>{t("Checkboxlabel")}<a href="#" style={{textDecoration: 'none'}}>&nbsp;{t("Checkboxlabel1")}</a></label>
            </Row>
              
            <LeadSource>
              <FormLabel htmlFor="Lead_Source">
                  lead source
                </FormLabel>
                <select id="Lead_Source" name="Lead Source">
                  <option value="-None-">-None-</option>
                  <option value="Facebook">Facebook</option>
                  <option selected={true} value="IVIP&#x20;WEBPAGE">IVIP WEBPAGE</option>
                  <option value="Twitter">twitter</option>
                </select>
              </LeadSource>
                  
                   <Row>
                      <ButtonSubmit type="submit">{t("BtnLabel")}</ButtonSubmit>
                  </Row>
            </form>

            
            
            </>
        )
    }
}


export default withTranslation() (Form);
